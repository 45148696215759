import React, { useState } from 'react'

// Components
import AtivacaoModal from 'src/components/UI/Forms/InterAtivacaoClientesForm'
import AtivacaoModal1 from 'src/components/UI/Forms/InterAtivacaoClientesForm1'
import { Modal } from 'src/components/Modal'

// layouts
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from './style'

// Sections
import Header from './sections/header/_index'
import LimiteNaHora from './sections/limiteNaHora/_index'
import SalarioNoInter from './sections/recebaSalarioNoInter/_index'
import CashbackCompras from './sections/cashbackCompras/_index'
import LibereMaisLimite from './sections/libereMaisLimite/_index'
import HelpCenter from '../helpCenter/_index'

// hooks
import useDomReady from 'src/hooks/window/useDomReady'

import pageContext from './pageContext.json'

const LimiteCredito = () => {
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)
  const [ openModal1, setOpenModal1 ] = useState(false)

  const openAccountPJFormModal = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <AtivacaoModal closeModal={() => setOpenModal(false)} />
    </Modal>
  )
  const openAccountPJFormModal1 = domReady && (
    <Modal isModalOpen={openModal1} setIsModalOpen={setOpenModal1} locationToRender={document.body}>
      <AtivacaoModal1 closeModal={() => setOpenModal1(false)} />
    </Modal>
  )
    return (
      <Wrapper>
        <Layout pageContext={pageContext}>
          {openAccountPJFormModal}
          {openAccountPJFormModal1}
          <Header />
          <LimiteNaHora setOpenModal1={setOpenModal1} />
          <SalarioNoInter />
          <CashbackCompras />
          <LibereMaisLimite setOpenModal={setOpenModal} setOpenModal1={setOpenModal1} />
          <HelpCenter />
        </Layout>
      </Wrapper>
    )
}

export default LimiteCredito
