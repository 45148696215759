import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const Section = styled.section`
  background-color: ${orange.extra};
  min-height: 267px;

  @media ${device.tablet} {
    min-height: 168px;
  }
  @media ${device.desktopLG} {
    min-height: 228px;
  }

  @media ${device.desktopXL} {
    min-height: 292px;
  }
`
