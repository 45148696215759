import React from 'react'
import Img from 'gatsby-image'
import useWidth from 'src/hooks/window/useWidth'

// Layouts
import usePageQuery from '../../../pageQuery'
import { Section, ImgSize, FaresCard } from './style'

const CashbackCompra = () => {
  const data = usePageQuery()
  const width = useWidth()
  return (
    <> {
      width < 768 ? (
        <Section className='py-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <h2 className='fs-24 lh-30 lh-md-50 text-grayscale--500'>Cashback nas suas compras</h2>
                <p className='fs-16 lh-19 lh-md-22 text-grayscale--400 py-4'>
                  Com o <strong>Crédito Debitado na Hora</strong>, você pode fazer compras no crédito à vista, independente de ter limite aprovado.
                  E o melhor: você ganha cashback por cada transação. Saiba como ativar:
                </p>
              </div>
              <ImgSize className='col-3'>
                <Img fluid={data.LimiteDeCreditoPassosMobile.fluid} alt='Passo a passo cashback' />
              </ImgSize>
              <div className='col-9'>
                <p className='fs-16 lh-19 text-grayscale--500 pb-3'>Acesse a aba “Cartões” do seu aplicativo Inter;</p>
                <p className='fs-16 lh-19 text-grayscale--500 py-4'>Clique na opção de configurações;</p>
                <p className='fs-16 lh-19 text-grayscale--500 pt-3 mb-0'>Ative a opção de Crédito Debitado na Hora.</p>
              </div>
            </div>
          </div>
        </Section>
      ) : (
        <Section className='py-5 d-md-flex position-relative align-items-center justify-content-md-center'>
          <div className='container'>
            <div className='row align-items-center justify-content-md-center'>
              <div className='col-md-12 text-md-center'>
                <h2 className='text-align-md-center fs-md-40 lh-md-50 text-grayscale--500 pb-md-4'>
                  Cashback nas suas compras
                </h2>
                <p className='fs-md-18 lh-md-22 text-grayscale--400 text-lg-left'>
                  Com o <strong>Crédito Debitado na Hora</strong>, você pode fazer compras no crédito à vista, independente de ter limite aprovado.
                  E o melhor: você ganha cashback por cada transação. Saiba como ativar:
                </p>
              </div>
              <ImgSize className='col-md-12'>
                <Img fluid={data.LimiteDeCreditoPassosTabletDesktop.fluid} alt='Passo a passo cashback' />
              </ImgSize>
            </div>
            <div className='col-12'>
              <div className='row'>
                <FaresCard className='col-md-4 px-2'>
                  <div className='d-flex align-items-center bg-gray rounded-3 px-4 px-xl-3'>
                    <div className='pt-md-0'>
                      <p className='fs-md-16 fs-lg-20 lh-md-20 lh-lg-25 text-grayscale--500 text-center'>
                        Acesse a aba “Cartões” do seu aplicativo Inter;
                      </p>
                    </div>
                  </div>
                </FaresCard>
                <FaresCard className='col-md-4 px-2'>
                  <div className='d-flex align-items-center bg-gray rounded-3 px-4'>
                    <div className='pt-md-3 pt-lg-0'>
                      <p className='fs-md-16 fs-lg-20 lh-md-20 lh-lg-25 text-grayscale--500 text-center mb-md-0 mb-lg-3'>
                        Depois, clique em “Configurações”;
                      </p>
                    </div>
                  </div>
                </FaresCard>
                <FaresCard className='col-md-4 px-2'>
                  <div className='d-flex align-items-center bg-gray rounded-3 px-4 px-md-3'>
                    <div className='pt-md-0'>
                      <p className='fs-md-16 fs-lg-20 lh-md-20 lh-lg-25 text-grayscale--500 text-center'>
                        Ative a opção de Crédito Debitado na Hora.
                      </p>
                    </div>
                  </div>
                </FaresCard>
              </div>
            </div>
          </div>
        </Section>
      )
    }
    </>
  )
}

export default CashbackCompra
