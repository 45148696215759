import React from 'react'

import { Section } from './style'

const SalarioNoInter = () => {
    return (
      <Section className='py-5 position-relative d-flex align-items-center'>
        <div className='container'>
          <div className='row align-items-md-center'>
            <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0'>
              <h2 className='fs-32 fs-lg-40 lh-40 lh-lg-50 text-grayscale--100 mb-md-0'>
                <span className='d-xl-block'>Receba seu salário no</span> Inter. É gratuito!
              </h2>
            </div>
            <div className='col-12 col-md-6 col-lg-5 offset-lg-1'>
              <p className='fs-16 fs-md-18 lh-19 lh-md-22 mb-0 text-grayscale--100'>
                Assim, a gente estreita nossa relação e você tem mais chances de conseguir liberação de crédito.
                Sem filas ou burocracia.
              </p>
            </div>
          </div>
        </div>
      </Section>
    )
}

export default SalarioNoInter
