import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  min-height: 542px;
  
  @media ${device.tablet} {
    min-height: 418px;
  }
`
export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    margin: 0 auto;
    max-width: 44px;
    max-height: 234px;
    z-index: 1;

    @media ${device.tablet} {
      top: 13px;
      max-width: 500px;
    }
    @media ${device.desktopLG} {
      top: 18px;
      max-width: 670px;
    }
    @media ${device.desktopXL} {
      top: 22px;
      max-width: 824px;
    }
  }
`

export const FaresCard = styled.div`
  @media ${device.desktopLG} {
    width: 296px;
  }
  p {
    font-family: 'Sora';
    padding-top: 34px;
    height: 128px;
    
    @media ${device.desktopLG} {
      padding-top: 70px;
      height: 175px;
    }
    
    @media ${device.desktopXL} {
      height: 175px;
    }
  }
`
